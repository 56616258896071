import { map, uniq, capitalize, find , uniqBy} from 'lodash'
import moment from 'moment';
import rand from '../../helpers/rand'; 
import ImageURL from '../../helpers/ImageURL';
import SiloSuffix from '../../helpers/SiloSuffix';
import icons from '../../helpers/Icons';
import { formatChartLabel, formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';

var streamSvg = require('!svg-inline-loader!../../../public/img/icons/stream.svg');
var socialSvg = require('!svg-inline-loader!../../../public/img/icons/social_music.svg');
var videoSvg = require('!svg-inline-loader!../../../public/img/icons/video.svg');
var downloadsSvg = require('!svg-inline-loader!../../../public/img/icons/download.svg');

export const artistsFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatTop,
    formatDetails,
    formatForTableOld,
    formatAutocomplete,
    formatAutocompleteItem,
    formatCard,
    formatStreamsStats,
    formatProfile,
    formatFollowersForChart,
    formatTotalFollowersForChart,
    formatProducts,
    formatArtistChannels,
    formatMetadata,
    formatMetadataFlat,
    formatTimeseries,
    formatMetadataSocial
};

function formatForPercentageChart(data, column){
    let dataset = [], 
    labels = [];

    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return {data: [], total: 0};
    let total = 0;
    for(let entry of data) {
        total = entry.total_entities;
        entry.id = entry.artist_id;
        entry.name = entry.artist_name;
        entry.logo = ImageURL(entry.image, entry.id, 'artists');
        if(entry.active && entry.curr_units)
            entry.engaged = Math.round((entry.active / entry.curr_units) * 100);
    };
    //data = AddTotal(data, 'artist');
    return {data, total};
   

}

function formatTop(data){
    if(!data)
        return [];    
    let total = 0;    
    data = map(data, (entry)=>{
        total = entry.total_entities;
        entry.id = entry.artist_id;
        entry.absoluteValue = entry.curr_units;
        entry.percentageValue = entry.units_diff = percentageValue(entry.curr_units, entry.prev_units);
        entry.playlists_diff = percentageValue(entry.curr_playlists, entry.prev_playlists);
        entry.engaged = entry.curr_units ? Math.round((entry.active / entry.curr_units) * 100) : 0;
        entry.code = null;
        entry.vendors = VendorHelper.VendorColors(entry.vendors);
        entry.units_change = entry.prev_units ? entry.curr_units - entry.prev_units : 0;
        return entry;
    });
    return {data, total};
}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatForTableOld(data){
    if(!data)
        return [];
    for(let entry of data.data) {
        entry.name = entry.artist;
        entry.logo = entry.image;
    }
    return data;
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;

    const entity = 'artists',
        name = hit.name? hit.name : hit._source.name,
        id = hit.id ? hit.id : hit._id;

    return {
        id,
        entity,
        name: `${name} (artist)`,
        logo: icons.entities[entity]
    };
}

function formatAutocompleteItem(hit){
    const entity = 'artists';
    const silo = SiloSuffix(hit.data_silo_codes);
    return {
        id: hit.id,
        entity,
        name: `${hit.name} ${silo}(artist)`,
        name_raw: `${hit.name} ${silo}`,
        logo: icons.entities[entity],
        image: ImageURL(hit.image, hit.id, 'artists')
    }
}


function formatAutocomplete(data) {
    let {artists}= data;
    return artists.map(formatAutocompleteItem);
}

function formatCard(data) {
    data = data.data;
    const channels = data.tracked_channels || []; 
    
    return {
        id: data.id,
        name: `${data.name} (artist)`,
        name_raw: `${data.name}`,
        logo: ImageURL(data.image, data.id, 'artists'),
        channels
    }
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams && metadata) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((artist, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, artist_id: artist.id});
                data.push(stream ? stream.curr_units : null);
            }    
            const silo = SiloSuffix(artist.data_silo_codes);

            return {
                id: artist.id,
                label: `${artist.name} ${silo}(artist)`,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatProfile(data, card) {
    let settings = {
        units: {icon: streamSvg, title: "streams", iconName: 'streams'},
        followers: {icon: videoSvg, title: "followers", iconName: 'followers'},
        products: {icon: downloadsSvg, title: "products", iconName: 'download'},
        tracks: {icon: socialSvg, title: "tracks", iconName: 'music'}
    },
    totals = [];
    
    data = data [0];
    data.id = card.data.id;
    data.name = card.data.name;
    data.image = card.data.image;
    
    for (let key of Object.keys(settings)){
        let { icon, title, iconName } = settings[key];
        totals.push({
            icon,
            title,
            iconName,
            key,
            "absoluteValue": (key == 'followers' ? card.data['spotify_followers'] : data[`curr_${key}`]),
            [`curr_${key}`]: (key == 'followers' ? card.data['spotify_followers'] : data[`curr_${key}`]),
            [`prev_${key}`]: (key == 'followers' ? card.data['spotify_followers'] : data[`curr_${key}`]),
        })
    }
    data.totals = totals;
    if(Array.isArray(card.data.tracked_channels)) {
        data.artists_channels = card.data.tracked_channels
        .map(channel=>{
            channel.show_in_profile = channel.channel_type_name !== 'youtube-video';
            channel.channel_type = getChannelCaption(channel.channel_type_name)
            return channel;
        })
    }
    else
        data.artists_channels = [];
    
    if(Array.isArray(card.data.related_artists)) {
        data.related_artists = card.data.related_artists.filter(artist=>artist.id!==null);
    }
    else
        data.related_artists = [];
    
    // dummy data
    data.genres = ['Electropop', 'Synth-Pop'];
    data.bio = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book';
    data.labels = ['Interscope', 'Polyvore'];
    data.origin = 'London, UK';
    //

    return data;
}

function _getFollowersData(data){
    return data.data.filter(item=>item.channel_type != 'youtube-video');
}

function _getChannelCaption(channel) {
    return channel
    .toLowerCase()
    .replace('-', ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace('Youtube', 'YouTube');
}

function getChannelCaption(channel) {
    return channel.match(/youtube/)?'YouTube':capitalize(channel);
}


function formatFollowersForChart({data}, metric = 'metric'){
    //data = _getFollowersData(data);
    
    let labels = [],
        datasets = [];
    
    if(data) {
        for(let channel of data) {
            let timeseries = channel.by_date;
            channel.channel_type = getChannelCaption(channel.channel_type);
            
            channel.label = `${channel.channel_name} (${channel.channel_type})`;
               
            for(let entryID in timeseries) {
                const entry = timeseries[entryID];
                if(entry.extract_date === null)
                    timeseries = timeseries.splice(entryID, 1);
                else   
                    labels.push(entry.extract_date);
            }
            channel.growth = timeseries.length ? (timeseries[timeseries.length-1][metric] - timeseries[0][metric]) : 0;
        }
        
        labels = uniq(labels).sort();
        
        datasets = data.map((channel, index)=>{
            let timeseries = [],
                absTimeseries = [],
                initialValue,
                previousValue = 0;
            for(let label of labels) {
                let value = 0;
                for(let item of channel.by_date) {
                    if(item.extract_date == label) {
                        if(item[metric]) {
                            value = item[metric];
                            previousValue = item[metric];
                        }
                        else {
                            value = previousValue;
                        }
                        break;
                    }    
                }
                if(value) {
                    absTimeseries.push(value);
                    if(initialValue === undefined)
                        initialValue = value;
                    timeseries.push(value - initialValue);
                }
                else {
                    absTimeseries.push(null);
                    timeseries.push(null);
                }
            }   
            return {
                id: channel.channel_id,
                label: channel.label,
                timeseries,
                absTimeseries,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length]                
                
            };
        });    
        
        labels = map(labels, (label)=>moment(label).format('DD MMM'));    
    }
    
    return {chart: { labels, datasets }, data};
    
}

function formatTotalFollowersForChart({data}, metric = 'metric'){
    //data = _getFollowersData(data);
    
    let labels = [],
        datasets = [];
    
    if(data) {
        for(let channel of data) {
            let timeseries = channel.by_date;
            channel.channel_type = getChannelCaption(channel.channel_type);
            
            channel.label = `${channel.channel_name} (${channel.channel_type})`;
               
            for(let entryID in timeseries) {
                const entry = timeseries[entryID];
                if(entry.extract_date === null)
                    timeseries = timeseries.splice(entryID, 1);
                else   
                    labels.push(entry.extract_date);
            }
            channel.growth = timeseries.length ? (timeseries[timeseries.length-1][metric] - timeseries[0][metric]) : 0;
        }
        
        labels = uniq(labels).sort();
        
        datasets = data.map((channel, index)=>{
            let timeseries = [],
                absTimeseries = [],
                initialValue,
                previousValue = 0;
            for(let label of labels) {
                let value = 0;
                for(let item of channel.by_date) {
                    if(item.extract_date == label) {
                        if(item[metric]) {
                            value = item[metric];
                            previousValue = item[metric];
                        }
                        else {
                            value = previousValue;
                        }
                        break;
                    }    
                }
                if(value) {
                    absTimeseries.push(value);
                    if(initialValue === undefined)
                        initialValue = value;
                    timeseries.push(value - initialValue);
                }
                else {
                    absTimeseries.push(null);
                    timeseries.push(null);
                }
            }   
            return {
                id: channel.channel_id,
                label: channel.label,
                timeseries,
                absTimeseries,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length]                
                
            };
        });    
        
        labels = map(labels, (label)=>moment(label).format('DD MMM'));    
    }
    
    let totalDataset = [],
        absTotalDataset = [];
    
    for(let dataset of datasets) {
        for(let index in dataset.timeseries) {
            if(!totalDataset[index])
                totalDataset[index] = 0;
            if(!absTotalDataset[index])
                absTotalDataset[index] = 0;
            
            totalDataset[index] += Number(dataset.timeseries[index]);
            absTotalDataset[index] += Number(dataset.absTimeseries[index]);
        }
    }
    
    const totalDatasetData = {
        id: 'total',
        label: 'Followers',
        timeseries: totalDataset,
        absTimeseries: absTotalDataset,
        fill: false,
    }
    
    return {chart: { labels, datasets:[totalDatasetData] }, data};
    
}



function formatProducts(data) {
    return data.map(item=>({
        internal_id: item.id,
        value: item.barcode,
        label: `${item.title} (${item.barcode})`
    }))
}

function formatArtistChannels(data, channelTypes) {
    return data.length ? data.map(item=>{
        const channel_type = channelTypes[item.channel_type_id],
            channel_caption = getChannelCaption(channel_type)
        return {
            channel_type,
            value: item.id,
            label: `${item.name} (${channel_caption})`
        }}) : null;
}

function formatMetadata(entities, metadata) {
    for(let entity of entities) {
        const metaEntity = find(metadata, {id: entity.id});
        if(metaEntity) {
            const silo = SiloSuffix(metaEntity.data_silo_codes);
            const name = `${metaEntity.name} ${silo}`;
            entity.name = name;
            entity.artist_name = name;
            entity.image = ImageURL(metaEntity.image, entity.id, 'artists');
        }
    }
    return entities;

}

function formatMetadataFlat(metadata) {
    let result = []
    for(let row of metadata) {
        const silo = SiloSuffix(row.data_silo_codes);
        result.push({
            id: row.id,
            entity: 'artists',
            name: `${row.name} ${silo}`,
            image: ImageURL(row.image, row.id, 'artists'),
            releaseDate: row.first_release_date,
            channels: row.tracked_channels || []
        });
    }
    return result;
}

function formatTimeseries(artists, metadata) {
    for(let artist of artists) {
        
        const metaEntity = find(metadata, {id: artist.artist_id});
        if(metaEntity) {
            artist.name = metaEntity.name;
        }

        artist.stms_by_date = artist.totals;
    }
        
    return artists;
}

function formatMetadataSocial(entities, metadata) {
    for(let entity of entities) {
        for(let key of Object.keys(entity)) {
            if(key.startsWith('channel_')) 
                entity[key] = [];
        }
        const metaEntity = find(metadata, {id: entity.artist_id});
        if(metaEntity) {
            entity.name = metaEntity.name;
            entity.artist_name = metaEntity.name;
            entity.image = ImageURL(metaEntity.image, entity.id, 'artists');
            entity.channels = metaEntity.tracked_channels ?  metaEntity.tracked_channels : [];
            for(let channel of entity.channels) {
                const key = `channel_${channel.channel_type_name}`;
                if(!entity[key])
                    entity[key] = [];
                entity[key].push(channel);
            }
        }
    }
    return entities;
}